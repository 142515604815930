//线下签约  鲜章申请签约
<template>
  <div class="applyOfflineSigning">
    <backtitle lable="在线签约" />
    <div class="signBackground">
      <!-- 头部汇总 -->
      <sign-header :summaryData="summaryData"></sign-header>
      <div class="contractAmount" v-if="summaryData.status !=='2'">
        合同总文件数 <span class="contractTotal">{{ contractTotal }}</span>
      </div>
      <!-- 盖章文件 -->
      <sign-title
        :showCheckbox="false"
        :checked.sync="checked"
        label="盖章文件"
        :amount="amount"
      ></sign-title>
      <base-table
        class="main-page-table"
        :columns="fileColumns"
        :showPage="false"
        :tableAttrs="{
          data: platformData,
          stripe: true,
        }"
        :dataSource.sync="platformData"
        ref="tableData"
        :loadCount="loadCount"
        :webPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="action" slot-scope="scope">
          <icon-button
            v-if="scope.row.currentFileId === scope.row.originalFileId"
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
          <el-upload
            class="upload-demo"
            action=""
            :http-request="
              function (url) {
                return uploadFile(url, scope.row, scope.$index);
              }
            "
            :show-file-list="false"
          >
            <icon-button
              :content="
                scope.row.currentFileId === scope.row.originalFileId
                  ? '重新上传'
                  : '上传'
              "
              :icon="
                scope.row.currentFileId === scope.row.originalFileId
                  ? 'iconfont iconzhongxinshangchuan'
                  : 'iconfont iconshangchuan'
              "
            />
          </el-upload>
          <icon-button
            v-if="scope.row.currentFileId === scope.row.originalFileId"
            @click="deldeFile(scope.row)"
            content="删除"
            icon="iconfont iconshanchu1"
          />
        </template>
      </base-table>
    </div>
    <!-- 底部提交按钮 -->
    <div class="footer">
      <base-button label="提交" @click="submit"></base-button>
    </div>
    <!-- 列表查看预览文件 -->
    <!-- <preview-dialog
      :previsible.sync="previsible"
      :fileId="fileId"
      :dialogType="'list'"
      :fileType="fileType"
    ></preview-dialog> -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseTable from '@/components/common/table/base-table/base-table'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import SignTitle from '../components/sign-title.vue'

import SignHeader from '../components/sign-header.vue'
import { fileTable } from './utils/offline-config'
// import { fileAPi } from '@/api/fileApi'
import { signApi } from '@/api/businessApi'

import Storage from '@/utils/storage'
export default {
  name: 'applyOfflineSigning',
  components: {
    BaseTable,
    BaseButton,
    backtitle,
    SignTitle,
    IconButton,

    SignHeader,
    PreView
  },
  data () {
    return {
      contractTotal: '20', // 合同文件总数
      summaryData: {}, // 签约数据
      count: 0,
      platformData: [], // 表格数据
      loadCount: 0,
      previsible: false, // 预览弹窗
      fileId: '',
      fileType: '',
      checked: false, // 选择按钮
      amount: '66' // 盖章文件数
    }
  },
  mounted () {
    // 获取缓存数据
    this.summaryData = Storage.getSession('onlineSginData')
    this.amount = this.summaryData.contractCount.toString()
    this.getSignDetail()
  },
  computed: {
    fileColumns () {
      return fileTable(this)
    }
  },
  methods: {
    // 获取文件列表
    getSignDetail () {
      signApi.getSignDetail({ taskId: this.summaryData.keyId }).then((res) => {
        if (res.data) {
          this.platformData = res.data
        }
      })
    },
    // 发起签章
    submit () {
      signApi.finishTask({ taskId: this.summaryData.keyId }).then(res => {
        if (res.data) this.success('提交成功')
      })
    },
    // 展示预览
    showPreview (data) {
      this.fileId = data.currentFileId ? data.currentFileId : ''
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      // this.previsible = true
      this.count++
    },
    // 调用文件服务上传文件
    // async Upload (param) {
    //   const formData = new FormData()
    //   formData.append('file', param.file)
    //   formData.append('belongCode', 'TEMPLATE')
    //   const res = await fileAPi.file(formData).then((res) => {
    //     if (res.data) {
    //       return res.data
    //     }
    //   })
    //   return res
    // },
    // 上传
    async uploadFile (param, row, index) {
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = ['.doc', '.txt', '.pdf'] // 可上传文件格式 待定
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传 格式文件') // 可上传文件格式 待定
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('docId', row.docId)
      // 构造参数
      this.fileSending(formData)
    },
    // 上传文件
    fileSending (data) {
      signApi.sginFilesupload(data).then(res => {
        if (res.data) {
          this.success('上传成功')
        }
      })
    },
    // 删除
    deldeFile (data) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除吗？',
        show: true,
        type: 'warning'
      })
        .then(() => {
          signApi.removeSginFiles({ docId: data.docId }).then((res) => {
            if (res.data) {
              this.success('删除成功')
              this.getSignDetail()
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped />
