//在线签约头部
<template>
  <div class="onlineTitle">
    <div class="left">
      <el-checkbox v-if="showCheckbox" v-model="checked" />
      <div>
        {{ label }}<span>({{ amount }})</span>
      </div>
    </div>
    <!-- 右侧按钮插槽 -->
    <div class="right">
      <slot name="btn"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checked: { // 选项值
      type: Boolean,
      default: false
    },
    showCheckbox: { // 是否显示选择框
      type: Boolean,
      default: false
    },
    amount: { // 数量
      type: String,
      default: '0'
    },
    label: { // 标题
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  created () {
    // currentChecked: {
    //   get () {
    //     return this.checked
    //   },
    //   set (val) {
    //     this.$emit('update:checked', val)
    //     console.log(val, '6666')
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.onlineTitle {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;

  width: 100%;
  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    font-weight: bold;
    font-size: 18px;
    color: #38373a;
    border-left: 6px solid #2862E7;
    padding-left: 13px;
    /deep/ .el-checkbox__inner {

      margin-right: 9px;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    width: 300px;
    margin-right: 15px;
  }
}
</style>
