// 头部汇总
<template>
    <form-info
      :count="count"
      :componentList="summaryForm"
      :model="summaryData"
      :showbor="true"
    />
</template>
<script>
import FormInfo from '@/components/packages/form-info/form-info.vue'

export default {
  props: {
    summaryData: Object
  },
  components: { FormInfo },
  data () {
    return {
      summaryForm: [
        {
          label: '业务编号',
          prop: 'businessCode'
        },
        {
          label: '供应商',
          prop: 'gysCompanyName'
        },
        {
          label: '申请金额(元)',
          prop: 'applyAmount'
        }
      ],
      count: 0
    }
  },
  mounted () {
    this.count++
  }
}
</script>
<style lang="scss" scoped>

</style>
