var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applyOfflineSigning"},[_c('backtitle',{attrs:{"lable":"在线签约"}}),_c('div',{staticClass:"signBackground"},[_c('sign-header',{attrs:{"summaryData":_vm.summaryData}}),(_vm.summaryData.status !=='2')?_c('div',{staticClass:"contractAmount"},[_vm._v(" 合同总文件数 "),_c('span',{staticClass:"contractTotal"},[_vm._v(_vm._s(_vm.contractTotal))])]):_vm._e(),_c('sign-title',{attrs:{"showCheckbox":false,"checked":_vm.checked,"label":"盖章文件","amount":_vm.amount},on:{"update:checked":function($event){_vm.checked=$event}}}),_c('base-table',{ref:"tableData",staticClass:"main-page-table",attrs:{"columns":_vm.fileColumns,"showPage":false,"tableAttrs":{
        data: _vm.platformData,
        stripe: true,
      },"dataSource":_vm.platformData,"loadCount":_vm.loadCount,"webPage":false},on:{"update:dataSource":function($event){_vm.platformData=$event},"update:data-source":function($event){_vm.platformData=$event}},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(_vm._s(scope.$index + 1))]}},{key:"action",fn:function(scope){return [(scope.row.currentFileId === scope.row.originalFileId)?_c('icon-button',{attrs:{"content":"预览","icon":"iconfont iconyulan"},on:{"click":function($event){return _vm.showPreview(scope.row)}}}):_vm._e(),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","http-request":function (url) {
              return _vm.uploadFile(url, scope.row, scope.$index);
            },"show-file-list":false}},[_c('icon-button',{attrs:{"content":scope.row.currentFileId === scope.row.originalFileId
                ? '重新上传'
                : '上传',"icon":scope.row.currentFileId === scope.row.originalFileId
                ? 'iconfont iconzhongxinshangchuan'
                : 'iconfont iconshangchuan'}})],1),(scope.row.currentFileId === scope.row.originalFileId)?_c('icon-button',{attrs:{"content":"删除","icon":"iconfont iconshanchu1"},on:{"click":function($event){return _vm.deldeFile(scope.row)}}}):_vm._e()]}}])})],1),_c('div',{staticClass:"footer"},[_c('base-button',{attrs:{"label":"提交"},on:{"click":_vm.submit}})],1),_c('pre-view',{attrs:{"fileId":_vm.fileId,"isOpen":true,"fileType":_vm.fileType,"count":_vm.count}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }